import React from 'react'
import tw, { theme, styled } from 'twin.macro'
import { Heading, Paragraph } from '~storybook/blocks/typography/index'
//
import Icons from '~storybook/icons'
import NavLink from '~utils/navlink'

const TertiaryStyle = styled(({ hasIcon, isSection, ...other }) => <NavLink {...other} />)`
  ${tw`font-thin py-0 mb-2 no-underline cursor-pointer transition-colors duration-200 flex justify-center`}
  ${tw`bg-transparent text-c-lang-selector-tertiaryText hover:text-c-lang-selector-tertiaryText hover:underline`}
  ${({ hasIcon }) => hasIcon && tw`pl-4 pr-4 justify-start`}

  svg {
    margin-left: 10px;
    display: inline-block;
    margin-top: 5px;

    * {
      fill: ${theme`colors['c-lang-selector'].fill`};
    }
  }
`

const Logo = styled.div`
  img {
    display: block;
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
    height: ${({ height }) => (height ? `${height}px` : 'auto')};
    margin-left: auto;
    margin-right: auto;

    @media (max-width: ${`${parseInt(`${theme`screens.md`}`.replace('px', ''), 10) - 1}px`}) {
      width: ${({ widthSm }) => (widthSm ? `${widthSm}px` : 'auto')};
      height: ${({ heightSm }) => (heightSm ? `${heightSm}px` : 'auto')};
    }
  }
`

const LanguageOption = styled.div`
  ${tw`flex flex-col items-center mb-4`}
  a {
    ${tw`text-center flex mx-auto`}
    width: auto;

    span {
      ${tw`text-xl flex mx-auto`}
    }
  }
`
const LanguageSelectorWrapper = styled.div`
  text-align: center;
  ${tw`py-20`}
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const LanguageSelector = ({ title, logo, options, fieldLanguageSplashLogo, fieldLanguageSplashSitetitle}) => {
  const getLogo = () => {
    if (logo && logo?.url && logo?.width && logo?.height) {
      const ratio = logo?.width / logo?.height
      let sizes
      if (ratio > 0.9 && ratio < 1.1) {
        sizes = {
          width: (60 * logo?.width) / logo?.height,
          height: (60 * logo?.height) / logo?.width,
          widthSm: (50 * logo?.width) / logo?.height,
          heightSm: (50 * logo?.height) / logo?.width
        }
      } else if (ratio > 1.5) {
        sizes = {
          width: (40 * logo?.width) / logo?.height,
          height: 40,
          widthSm: (30 * logo?.width) / logo?.height,
          heightSm: 30
        }
      } else {
        sizes = {
          width: 187,
          height: (187 * logo?.height) / logo?.width,
          widthSm: 139,
          heightSm: (139 * logo?.height) / logo?.width
        }
      }

      return (
        <Logo {...sizes}>
          <img alt="logo" src={logo?.url} />
        </Logo>
      )
    }

    return null
  }

  

  return (
    <LanguageSelectorWrapper data-search-ignore="true">
      <div className="mb-12 md:mb-20">
        {fieldLanguageSplashLogo && logo && logo.url && logo.width && logo.height && <div>{getLogo()}</div>}
        {fieldLanguageSplashSitetitle && title && (
          <div>
            <Heading className="m-0 p-0" level="h1">
              {title}
            </Heading>
          </div>
        )}
      </div>

      <>
        {Array.isArray(options) &&
          options.map((language) => {
            return (
              <LanguageOption key={`lang-sel-${language?.id}`}>
                <TertiaryStyle to={language?.url} hasIcon>
                  <Heading level="h3">{language?.title}</Heading>
                  <Icons.ArrowRight />
                </TertiaryStyle>
                <Paragraph className="text-c-lang-selector-text mt-0 mb-5" text={language?.subtitle} />
              </LanguageOption>
            )
          })}
      </>
    </LanguageSelectorWrapper>
  )
}

export default LanguageSelector
